.linkContainer {
  display: flex;
  align-items: center;
  background-color: rgb(232, 232, 232); /* Slightly dark background */
  padding: 10px;
  border-radius: 30px;
  /* max-width: 100%; */
  width: fit-content;
  overflow-wrap: break-word; /* Handle long URLs */
}

.publicLink {
  color: rgb(53, 109, 255); /* Light blue for the link */
  text-decoration: none;
  flex: 1;
  word-wrap: break-word;
}

.publicLink:hover {
  text-decoration: underline;
}

.copyButton {
  margin-left: 30px;
  padding: 6px 12px;
  background-color: #232323;
  border: none;
  color: white;
  border-radius: 15px;
  cursor: pointer;
}

.copyButton:hover {
  background-color: #000000;
}
