/* App specific styles */
.app {
  margin: 20px;
  /* font-family: Arial, sans-serif; */
}

.mainContent {
  display: flex;
  /* justify-content: space-between; */
  align-items: start; /* Aligns items at the start of the flex container */
  margin-bottom: 10px; /* Spacing between main content and Department component */
}

.leftContent {
  /*flex: 1;  Takes up remaining space after allocating to rightContent */
  /* background-color: rgb(99, 150, 195); */
}

.roundedDiv {
  
}

.departmentPicker {
  margin: 30px;
}

.topRightRoundedDiv {
  margin-bottom: 10px;
}
.rightContent {
  /*width: 300px;  Fixed width for summations */
}

/* Styles specific to the Department and StaffChart components */
.department {
  width: 100%;
}

.staffChart {
  width: 100%;
}

.centeredContent {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically if needed */
  width: 100%;
  margin-top: 20px; /* Adjust the spacing as needed */
}