.weekContainer {
  display: flex;
}
.weekContainer:hover {
  background-color: rgb(235, 235, 235);
}

.dayContainer {
  cursor: pointer;
}
.dayContainer:hover {
  background-color: rgb(173, 173, 173);
}
.personContainer {
  cursor: pointer;
}
.personContainer:hover {
  background-color: rgb(173, 173, 173);
}
