.container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .chartsContainer {
    display: flex;
    /* justify-content: space-around; */
    /*width: 100%;  */
  }
  
  .chartContainer {
    flex: 1; /* Each chart takes equal space */
  }
  
  .dayPicker {
    margin: 20px 0;
  }
  
  .styledButton {
    margin: 0 5px;
    /* padding: 10px; */
    height: 30px;
    padding-left: 15px;
    padding-right: 15px;
    color: #fff;
    background-color: #81A1F2;
    border: none;
    cursor: pointer;
    border-radius: 20px;
    font-size: 14px;
  }
  
  .styledButton.active {
    background-color: #82ca9d; /* Active day highlighted in a different color */
  }
  .styledButton:hover {
    background-color: #6289ec; /* Active day highlighted in a different color */
  }
  