.popupMeetingDiv {
  background-color: #eee;
  cursor: pointer;
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
}

.popupMeetingDiv:hover {
  background-color: #dedede;
}

.personRow {
  background-color: var(--isEven);
  display: flex;
}

.personRow:hover {
  background-color: #bbb;
}

/* .weekSumDiv:hover {
  background-color: #525252; 
  color: #fff;
} */

.isHoveredWeekAndDept {
  background-color: #bbb;
}

