/* Switch.module.css */

:root {
    --switch-width: 46px;
    --switch-height: 24px;
    --switch-bg: rgb(131, 131, 131);
    --switch-checked-bg: rgb(53, 109, 255);
    --switch-offset: calc((var(--switch-height) - var(--circle-diameter)) / 2);
    --switch-transition: all 0.2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
    --circle-diameter: 18px;
    --circle-bg: #fff;
    --circle-shadow: 1px 1px 2px rgba(146, 146, 146, 0.45);
    --circle-checked-shadow: -1px 1px 2px rgba(163, 163, 163, 0.45);
    --circle-transition: var(--switch-transition);
    --icon-transition: all 0.2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
    --icon-cross-color: var(--switch-bg);
    --icon-cross-size: 6px;
    --icon-checkmark-color: var(--switch-checked-bg);
    --icon-checkmark-size: 10px;
    --effect-width: calc(var(--circle-diameter) / 2);
    --effect-height: calc(var(--effect-width) / 2 - 1px);
    --effect-bg: var(--circle-bg);
    --effect-border-radius: 1px;
    --effect-transition: all 0.2s ease-in-out;
  }
  
  .switch input {
    display: none;
  }
  
  .switch {
    display: inline-block;
  }
  
  .switch svg {
    transition: var(--icon-transition);
    position: absolute;
    height: auto;
  }
  
  .checkmark {
    width: var(--icon-checkmark-size);
    color: var(--icon-checkmark-color);
    transform: scale(0);
    fill: var(--icon-checkmark-color);
  }
  
  .cross {
    width: var(--icon-cross-size);
    color: var(--icon-cross-color);
    fill: var(--icon-cross-color);
  }
  
  .slider {
    box-sizing: border-box;
    width: var(--switch-width);
    height: var(--switch-height);
    background: var(--switch-bg);
    border-radius: 999px;
    display: flex;
    align-items: center;
    position: relative;
    transition: var(--switch-transition);
    cursor: pointer;
  }
  
  .circle {
    width: var(--circle-diameter);
    height: var(--circle-diameter);
    background: var(--circle-bg);
    border-radius: inherit;
    box-shadow: var(--circle-shadow);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--circle-transition);
    z-index: 1;
    position: absolute;
    left: var(--switch-offset);
  }
  
  .slider::before {
    content: '';
    position: absolute;
    width: var(--effect-width);
    height: var(--effect-height);
    left: calc(var(--switch-offset) + (var(--effect-width) / 2));
    background: var(--effect-bg);
    border-radius: var(--effect-border-radius);
    transition: var(--effect-transition);
  }
  
  /* actions */
  
  input:checked + .slider {
    background: var(--switch-checked-bg);
  }
  
  input:checked + .slider .checkmark {
    transform: scale(1);
  }
  
  input:checked + .slider .cross {
    transform: scale(0);
  }
  
  input:checked + .slider::before {
    left: calc(
      100% - var(--effect-width) - (var(--effect-width) / 2) -
        var(--switch-offset)
    );
  }
  
  input:checked + .slider .circle {
    left: calc(100% - var(--circle-diameter) - var(--switch-offset));
    box-shadow: var(--circle-checked-shadow);
  }
  