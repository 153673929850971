.editText {
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  border-radius: 15px;
  /* box-sizing: border-box; */
}

.editText:hover {
  background-color: #efefef;
  outline: 1px solid #ccc;
  /* box-sizing: border-box; */
}