.container {
    background-color: var(--fourth-color);
    margin-bottom: 5px;
    max-width: 100%;
    align-items: center;
}

.header {
    margin: 0;
    padding: 10px 0;
}

.flexContainer {
    display: flex;
    /* width: 75%; */
    margin-left: 15px;
    margin-right: 5px;
    align-items: center;
    /* background-color: rgb(237, 255, 237); */
}

