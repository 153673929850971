
.selectOption {
  /* background-color: #eee; */
  cursor: pointer;
  /* border-radius: 15px 0 0 15px; */
  padding-left: 5px;
  padding-right: 5px;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24); */
  border: 1px solid #ccc;
}


.selectOption:hover {
  background-color: #b3b3b38f;
}

.selectOption.selected {
  background-color: #c1d5ff;
  /* color: #fff; */
  /* color: white; */
}
.selectOption:not(.selected) {
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.leftItem {
  border-radius: 15px 0px 0px 15px;
}
.rightItem {
  border-radius: 0px 15px 15px 0px ;
}