.container {
  position: relative;
  width: 400px;
}

.selectBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: white;
  cursor: pointer;
  min-height: 38px;
}

.selectBox:hover {
  background-color: #efefef;
}

.placeholder {
  color: #888;
}

.selectedItem {
  display: inline-flex;
  align-items: center;
  /* background-color: #c7daf3; */
  /* background-image: linear-gradient(to left, #96c8ff, #8cc2fe, #81bcfe, #76b6fd, #6bb0fc, #64acfb, #5da9fb, #56a5fa, #52a3fa, #4ea1f9, #4a9ef9, #469cf8); */
  /* background-image: linear-gradient(to left top, #96c8ff, #8cc2fe, #81bcfe, #76b6fd, #6bb0fc, #6aaffc, #68affb, #67aefb, #70b3fb, #79b8fb, #81bcfb, #8ac1fb); */
  /* background-image: linear-gradient(to left top, #dee9ff, #d9e6ff, #d5e3ff, #d0e0ff, #cbddff, #cbddff, #ccddff, #ccddff, #d1e0ff, #d6e4fe, #dbe7fe, #e0eafd); */
  /* background-image: linear-gradient(to left top, #e9f0ff, #e2ecff, #dce7ff, #d5e3ff, #cedfff, #cedfff, #cedfff, #cedfff, #d5e4ff, #dce9ff, #e4edff, #ebf2ff); */
  background-color: #c5d9ff;
  border-radius: 16px;
  /* padding: 2px 8px; */

  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 2px;
  margin: 2px;
  font-size: 14px;
}

.removeButton {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background: none;
  border: none;
  color: #888;
  font-size: 14px;
  cursor: pointer;
  margin-left: 0px;
}

.arrow {
  margin-left: auto;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.option {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.option:hover {
  background-color: #f5f5f5;
}

.option.selected {
  color: #007bff;
}

.checkmarkContainer {
  width: 20px; /* Fixed width space for the checkmark */
  display: inline-block;
}

.checkmark {
  display: inline-block;
  margin-right: 8px;
}
