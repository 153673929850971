.dynamicButtonBar {
    width: 100%;
    height: 48px;
    /* background-color: #bcd0eb; */
    display: flex;
    align-items: center;
    padding: 0 16px;

  }
  
  .buttonContainer {
    /* flex-grow: 1; */
    display: flex;
    align-items: center;
    /* overflow-x: auto; */
    /* position: relative; */
    /* max-height: 500%; */
    /* z-index: 3; */
  }
  
  .greenButtonContainer {
    /* flex-shrink: 0; */
    display: flex;
    align-items: center;
  }

  .greenButtonContainer > * { /* Targets all direct children of .greenButtonContainer */
    height: 30px;  
    /* max-width: 300px;    */
    /* min-width: 230px;    */
  }
  
  .greenButton {
    background-color: #10B981;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    margin-right: 8px;
    flex-shrink: 0;
    width: 8%;
    min-width: 80px;
  }
  
  .addButton {
    background-color: #3B82F6;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    flex-shrink: 0;
  }
  
  .dummyButtonContainer {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
  
  .dummyButton {
    background-color: #EF4444;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    margin-right: 8px;
  }
  
  .dummyButton:last-child {
    margin-right: 0;
  }

  .mamaContainer:hover {
    background-color: #e6e6e6;
  }

  .mamaContainer {
    background-color: #fff;
  }

  .selected {
    background-color: #e6e6e6;
  }