.itemSelector {
  display: flex;
  flex-direction: column;
  position: relative;
  /* width: 200px; Adjust as needed */
  border-radius: 20px;
}

.item {
  padding: 10px 10px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  line-height: 1.1; /* Ensure consistent vertical alignment */
  justify-content: center;
  min-width:23px;
}

.item:hover {
  background-color: rgb(221, 221, 221);
}

.item.selected {
  font-weight: 600;
}

.dot {
  position: absolute;
  right: 6px;
  width: 8px;
  height: 8px;
  background-color: rgb(53, 109, 255);
  border-radius: 50%;
  transition: all 0.3s ease;
}

.dot.animating {
  border-radius: 4px;
}

.plusButton {
  border: 0;
  /* background-color: #d6f8cc; */
  background-color: white;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.plusButton:hover {
  /* background-color: rgb(198, 198, 198); */
}
.minusButton {
  border: 0;
  /* background-color: #fdd6d6; */
  padding: 10px 10px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.minusButton:hover {
  /* background-color: #f6bfbf; */
}

/* .plusButton:hover {
  background-color: rgb(245, 245, 245);
} */

.container {
  position: relative;
}

.dropdown {
  position: absolute;
  left: 100%;
  top: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.container:hover .dropdown {
  opacity: 1;
  visibility: visible;
}

.dropdown button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.dropdown button:hover {
  background-color: rgb(245, 245, 245);
}

.leftMostItem {
  border-radius: 20px 20px 0 0px;
  /* padding-left: 10px; */
}

.rightMostItem {
  border-radius: 0 0 20px 20px;
}

