.container {
  position: relative;
}

.button {
  position: fixed;
  top: 26px;
  right: 26px;
  z-index: 50;
  width: 50px;
  height: 45px;
  /* background-color: #e2e2e2; */
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: transparent;

}

.button:hover{
  /* background-color: rgba(252, 252, 252, 0.612); */
  /* box-shadow:  0 1px 2px rgba(0, 0, 0, 0.24); */
  border: 1px solid #ccc;
}



.burgerIcon,
.burgerIcon::before,
.burgerIcon::after {
  content: '';
  display: block;
  position: absolute;
  height: 4px;
  width: 45px;
  border-radius: 10px;
  background-color: #f5f5f5;
  transition: transform 0.3s;
}

.burgerIcon {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.burgerIcon::before {
  top: -12px;
}

.burgerIcon::after {
  bottom: -12px;
}

.open .burgerIcon {
  background-color: transparent;
}

.open .burgerIcon::before {
  transform: translateY(12px) rotate(45deg);
}

.open .burgerIcon::after {
  transform: translateY(-12px) rotate(-45deg);
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 270px;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 40;
}

.menuList {
  margin-top: 70px;
  padding: 0;
  list-style-type: none;
}

.menuItem {
  display: block;
  padding: 12px 24px;
  /* color: #3366cc; */
  color: #000;
  text-decoration: none;
  font-size: 1.3em;
  font-weight: 400;
  cursor: pointer;
}

.menuItem:hover {
  background-color: #e6e6e6;

}