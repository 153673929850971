.itemSelector {
  display: flex;
  position: relative;
}

.item {
  padding: 10px 10px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.plusButton {
  border: 0;
  /* background-color: #ceffb1; */
  /* background-color: #F3F3F6; */
  background-color: transparent;
  /* padding: 10px 20px; */
  /* width: 100%; */
  width: fit-content;

  text-align: left;
  cursor: pointer;
  border-radius: 20px;
  margin-top: 0px;
  padding: 4px 8px 4px 8px;
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.plusButton:hover {
  /* background-color: rgb(215, 231, 255); */
  outline: 1px solid #ccc;
  /* text-decoration: underline;
  text-decoration-thickness: 1px;  */
}

.pulsating {
  position: relative;
  animation: pulsate 2s ease-out infinite;
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}


.item:hover {
  background-color: #e5e3fb;
  /* color: rgb(53, 109, 255); */
  /* color: #6f6f6f; */
}

.plusButton:hover {
  /* background-color: #b2e4a3; */
}

.item.selected {
  /* color: rgb(53, 109, 255); */
  /* background-color: rgb(255, 255, 255); */
  font-weight: 400;
}
.item.selected:hover {
  /* color: #fff; */
  font-weight: 400;
}

.underline {
  position: absolute;
  bottom: 0px;
  height: 4px;
  /* background-color: #a4c7ff; */
  background-color: rgb(83, 149, 255);
  transition: transform 0.3s ease, width 0.3s ease;
}

.leftMostItem {
  border-radius: 20px 0 0 20px;
  padding-left: 20px;
}

.rightMostItem {
  border-radius: 0 20px 20px 0;
}

.bottomItem {
  border-radius: 0 0 20px 20px;
}

.container {
  position: relative;
}

.dropdown {
  position: absolute;
  display: flex;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #e5e3fb;
  overflow: hidden;
  transition: height 0.3s ease;
  transition-delay: 0.3s; /* Add delay here */
}

.container:hover .dropdown {
  height: 100%;
}

/* Reset the delay when un-hovering */
.container:not(:hover) .dropdown {
  transition-delay: 0s;
}

.dropdown button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #e5e3fb;
  cursor: pointer;
}

.dropdown button:hover {
  background-color: #d3d0ff;
}