.addButton {
  border: 0px;
  margin-top: 45px;
  margin-bottom: 38px;
  margin-left: 10px;
  background-color: #abeb96;
  border-radius: 20px;
  font-size: 15px;
  padding: 5px;
  /* padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px; */
  
}

.addButton:hover {
  background-color: #88bb77;
}

.addButton:active {
  transform: translateY(1.5px);
}

.addButtonSmall {
  border: 0px;
  /* margin-top: 15px;
  margin-bottom: 40px; */
  background-color: #e9e9e9;
  border-radius: 20px;
  font-size: 20px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  
}

.addButtonSmall:hover {
  background-color: #bcbcbc;
}

.addButtonSmall:active {
  transform: translateY(1.5px);
}