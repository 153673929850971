.personBar {
  height: 30px;
  width: var(--width);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  margin-left: var(--offset);
  /* margin-top: 6px; */
  position: absolute;
  color: black;
  cursor: pointer;
  background-image: linear-gradient(to right, #ebd1ec, #f6cce0, #fdc9d2, #fec9c2, #f9cab3, #f7cdae, #f3d0a9, #edd3a6, #f0d7a7, #f2daa7, #f4dea8, #f6e2a9);
  transition: background-color 0.1s ease;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box; 
  /* outline: 1px solid #393939; */

}

.personGhostBar, .meetingGhostBar, .breakGhostBar {
  height: 30px;
  width: var(--width);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  margin-left: var(--offset);
  /* margin-top: 6px; */
  position: absolute;
  color: black;
  cursor: pointer;
  background-color: #f1f1f1;
  /* background-image: linear-gradient(to right, #ebd1ec, #f6cce0, #fdc9d2, #fec9c2, #f9cab3, #f7cdae, #f3d0a9, #edd3a6, #f0d7a7, #f2daa7, #f4dea8, #f6e2a9); */
  /* transition: background-color 0.1s ease; */
  
}
.personGhostBar:hover, .meetingGhostBar:hover, .breakGhostBar:hover {
  background-color: #cbcbcb;
}

.personBar:hover {
  background-image: linear-gradient(to right, #d0b7d1, #dcb6c9, #e4b5bf, #e8b7b3, #e7baa9, #e6bda5, #e4c0a1, #e0c49e, #e1c79e, #e1ca9d, #e1cd9d, #e1d09d);
}
/* .breakBar:hover {
  background-image: linear-gradient(to left, #d3c5d4, #d5c5d5, #d6c5d5, #d8c4d6, #dac4d6, #dac2d6, #dbc1d5, #dbbfd5, #dabbd4, #dab7d2, #d9b2d1, #d8aecf);
}
.aHBar:hover {
  background-image: linear-gradient(to right, #f4d78f, #f4d894, #f3da98, #f3db9d, #f3dca1, #f3dda5, #f3dea8, #f3dfac, #f3e0b0, #f4e2b5, #f4e3b9, #f4e4bd);

} */

.time {
  padding: 0 4px;
}

.name {
  font-weight: 500;
}

.breakBar {
  height: 30px;
  width: var(--width);
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  margin-left: var(--offset);
  margin-top: 6px;
  position: absolute;
  color: black;
  cursor: pointer;
  background-image: linear-gradient(to left, #eadbeb, #ebd9eb, #ebd7ea, #ecd4ea, #edd2e9, #edd0e8, #edcde8, #edcbe7, #ecc8e6, #ecc5e5, #ebc1e3, #ebbee2);
  /* z-index: 2; */
}
.aHBar {
  height: 30px;
  width: var(--width);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin-left: var(--offset);
  margin-top: 6px;
  position: absolute;
  color: black;
  cursor: pointer;
  background-image: linear-gradient(to right, #f4d78f, #f4d894, #f3da98, #f3db9d, #f3dca1, #f3dda5, #f3dea8, #f3dfac, #f3e0b0, #f4e2b5, #f4e3b9, #f4e4bd);
  /* background-image: linear-gradient(to left, #eadbeb, #ebd9eb, #ebd7ea, #ecd4ea, #edd2e9, #edd0e8, #edcde8, #edcbe7, #ecc8e6, #ecc5e5, #ebc1e3, #ebbee2); */
}
.meetingBar {
  height: 30px;
  width: var(--width);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin-left: var(--offset);
  margin-top: 6px;
  color: black;
  cursor: pointer;
  background-image: linear-gradient(to right, #fbbf9b, #fbc19f, #fac4a3, #fac6a7, #f9c8ab, #f9caae, #f9ccb2, #f9ceb5, #fad1b8, #fad3bc, #fbd6bf, #fbd8c3);
  position: absolute;
  /* background-image: linear-gradient(to right, #f4d78f, #f4d894, #f3da98, #f3db9d, #f3dca1, #f3dda5, #f3dea8, #f3dfac, #f3e0b0, #f4e2b5, #f4e3b9, #f4e4bd); */
  /* background-image: linear-gradient(to left, #eadbeb, #ebd9eb, #ebd7ea, #ecd4ea, #edd2e9, #edd0e8, #edcde8, #edcbe7, #ecc8e6, #ecc5e5, #ebc1e3, #ebbee2); */
}

.meetingBar:hover, .aHBar:hover, .breakBar:hover{
  border-radius: 12px 12px 0 0;
}
