/* CSS */
.selector-30 {
  appearance: none;
  background-color: #fff;
  border: 0px solid #D6D6E7;
  /* background-image: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="iso-8859-1"%3F%3E%3C!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg fill="%23000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve"%3E%3Cpath id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/%3E%3C/svg%3E'); */
  border-radius: 20px;
  /* box-shadow: rgba(45, 35, 66, 0.2) 0 1px 2px, rgba(45, 35, 66, 0.1) 0 2px 4px -1px, #D6D6E7 0 -1px 0 inset; */
  /* box-sizing: border-box; */
  color: #36395A;
  /* font-family: "JetBrains Mono", monospace; */
  height: 35px;
  padding-left: 16px;
  padding-right: 40px; /* Space for dropdown arrow */
  font-size: 18px;
  outline: none;
  transition: box-shadow .15s, transform .15s, border-color .15s;
  /* background-image: url('data:image/svg+xml;utf8,<svg fill="%2336395A" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>'); */
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px 16px;
  cursor: pointer;
  text-align: center;
}

.selector-30:focus {
  border-color: #36395A;
  /* box-shadow: #D6D6E7 0 0 0 1px inset, rgba(45, 35, 66, 0.2) 0 1px 2px, rgba(45, 35, 66, 0.1) 0 2px 4px -1px, #D6D6E7 0 -1px 0 inset; */
}

.selector-30:hover {
  /* box-shadow: rgba(45, 35, 66, 0.2) 0 2px 4px, rgba(45, 35, 66, 0.1) 0 4px 8px -2px, #D6D6E7 0 -1px 0 inset; */
  transform: translateY(-1px);
  background-color: #f1f1f1;
}

.selector-30:active {
  /* box-shadow: #D6D6E7 0 1px 3px inset; */
  transform: translateY(1px);
}
