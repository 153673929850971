.flexContainer {
  display: flex;
  /* width: 120px; */
  justify-content: space-between;
}


.input {
  background-color: transparent;
  border: 2px solid transparent;
  box-sizing: border-box;
  /* width: 3.5em; */
  font-family: 'Axiforma';
  text-align: center;
  /* border-radius: 5px; */
  font-size: 1em;
  font-weight: 500;
}

.wHInput {
  width: 3.4em;
}

.bLInput {
  width: 4em;

}

.input:focus {
  /* border: 2px solid red; */
  background-color: #e3eeff;
  outline: 1px;
  box-sizing: border-box;
}

.input:hover {
  background-color: #e3eeff;
}

.input::placeholder {
  color: #ccc;
}

.input:focus::placeholder, .input:hover::placeholder {
  color: #8f8f8f;
  /* color: #af68ed; */
}