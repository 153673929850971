.tabContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 10px;
  position: relative;
  /* background-color: #f1f3f4; */
}

.tab {
  padding: 8px 16px;
  margin-right: 2px;
  background-color: #ffffff64;
  border: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  box-shadow:  0 1px 2px rgba(0, 0, 0, 0.24);

  /* font-weight:lighter; */

  /* font-weight: 200; */
  /* transition: background-color 0.3s; */
}

.tab::before {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 8px 2px;
  z-index: -8;

}
.tab:hover {
  background-color: #b3b3b38f;
}

.tab.selected {
  background-color: #ffffff;
  position: relative;
}

.tab.selected::after {
  content: '';
  position: absolute;
  /* bottom: -2px; */
  left: 0;
  right: 0;
  /* height: 2px; */
  background-color: #ffffff;
}

.tabContent {
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.styledButton {
  margin: 0 5px;
  /* padding: 10px; */
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  color: #fff;
  background-color: #81A1F2;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  font-size: 14px;
}
