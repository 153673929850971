.daySelector {
  position: relative;
  width: 100px;
}

.selectorButton {
  width: 100%;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 20px;
  cursor: pointer;
}
.selectorButton:hover {
  background-color: #efefef;
}

.para {
  font-size: 16px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: white;
  border: 0px solid #ccc;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
}

.dropdown li {
  padding: 10px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #f0f0f0;
}