.container {
  background-color: #eee;
  border-radius: 15px;
  padding: 7px;
  /* margin-bottom: 5px; */
  margin-left: 10px;
  margin-top: 10px;
  
  cursor: pointer;
  max-width: 110px;
  height: fit-content;
  /* overflow-y: hidden; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* text-overflow:ellipsis; */
}

.container:hover {
  background-color: #d2d2d2;
}

.dayContainer {
  background-color: #fff;
  cursor: pointer;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dayContainer:hover {
  background-color: #eee;
}

.dayContainerSelected {
  /* background-color: #eee; */
  font-weight: 500;
  color: rgb(53, 109, 255);
}

.addButton {

}

.addButton:hover {
  
}

/* .dayColumn {
  background-color: #fff;
  cursor: pointer;
}

.dayColumn:hover {
  background-color: #eee;
} */