.iconButton {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    position: relative;
}

.iconButton:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 12px;
}

.iconButtonSettings svg {
    display: block;
    left: 50%;
    top: 50%;
    width: 60%;
    height: 60%;
}

.iconButtonRemove svg {
    display: block;
    left: 50%;
    top: 50%;
    width: 60%;
    height: 60%;
}
