.input {
  width: 50px;
  border-radius: 15px;
  /* align-items: center; */
  text-align: center;
  font-size: 1em;
  font-family: 'Axiforma', 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  border: 1px solid #ccc;
}

.selectButton {

}

.leftButton {

}

.rightButton {

}