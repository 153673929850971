.personBar {
  width: 100%; /* Bars are now narrow in width */
  height: var(--height); /* Height is controlled by the time span */
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin-top: var(--offset); /* Vertical offset instead of margin-left */
  /*margin-left: 6px;  Small horizontal spacing between bars */
  position: absolute;
  color: black;
  cursor: pointer;
  background-image: linear-gradient(to bottom, #ebd1ec, #f6cce0, #fdc9d2, #fec9c2, #f9cab3, #f7cdae, #f3d0a9, #edd3a6, #f0d7a7, #f2daa7, #f4dea8, #f6e2a9);
  transition: background-color 0.1s ease;
  
}

/* .personBar:hover {
  background-image: linear-gradient(to bottom, #d0b7d1, #dcb6c9, #e4b5bf, #e8b7b3, #e7baa9, #e6bda5, #e4c0a1, #e0c49e, #e1c79e, #e1ca9d, #e1cd9d, #e1d09d);
} */
.emptyPersonBar {
  padding: 5px;
  padding: 3px 25px 3px 25px;
  /* width: 25px; */
  margin: 0px;
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  /*margin-left: 6px;  Small horizontal spacing between bars */
  cursor: pointer;
}
.emptyPersonBar:hover {
  background-color: #e1e1e1;
}

.emptyPersonBarThicc {
  padding: 5px 25px 5px 25px;
  /* width: 25px; */
  margin: 0px;
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  /*margin-left: 6px;  Small horizontal spacing between bars */
  cursor: pointer;
}

.emptyPersonBarThicc:hover {
  background-color: #e1e1e1;
}
.emptyPersonBarThiccNarrow {
  padding: 5px 5px 5px 5px;
  /* width: 25px; */
  margin: 0px;
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  /*margin-left: 6px;  Small horizontal spacing between bars */
  cursor: pointer;
}

.emptyPersonBarThiccNarrow:hover {
  background-color: #e1e1e1;
}

.hoveredBar {
  /* background-color: #e880ec; */
  background-image: linear-gradient(to bottom, #f68bb0, #fc8ca1, #fe9093, #fd9586, #f99b7a, #f89f72, #f6a56b, #f2aa64, #f3af5c, #f2b454, #f0ba4b, #ecc043);
}

.unHoveredBar {
  background-image: linear-gradient(to bottom, #eaddeb, #f1dae7, #f7d8e0, #fcd6d7, #fdd6cd, #fdd7c8, #fbd9c2, #f8dbbe, #f8ddbd, #f7e0bb, #f6e2bb, #f4e5ba);
}

.rotatedText {
  transform: rotate(90deg);
  white-space: nowrap;
  transform-origin: center;
}