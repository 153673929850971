/* @font-face {
  font-family: 'CustomFont';
  src: url('.pring-DEMO-toruspro-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma/Axiforma-Regular.woff2') format('woff2'),
       url('./Axiforma/Axiforma-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  ascent-override: 85%; /* Adjust these values as needed */
  descent-override: 15%;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma/Axiforma-Bold.woff2') format('woff2'),
       url('./Axiforma/Axiforma-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  ascent-override: 85%; /* Adjust these values as needed */
    descent-override: 15%;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma/Axiforma-Thin.woff2') format('woff2'),
       url('./Axiforma/Axiforma-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  ascent-override: 85%; /* Adjust these values as needed */
    descent-override: 15%;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma/Axiforma-Medium.woff2') format('woff2'),
       url('./Axiforma/Axiforma-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  ascent-override: 85%; /* Adjust these values as needed */
    descent-override: 15%;
}