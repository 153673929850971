:root {
  --switch-width: 46px;
  --switch-height: 24px;
  --switch-dual-bg: rgb(53, 109, 255);
  --switch-transition: all 0.2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
  --circle-diameter: 18px;
  --circle-bg: #fff;
  --circle-shadow: 1px 1px 2px rgba(146, 146, 146, 0.45);
  --circle-transition: var(--switch-transition);
  --icon-transition: all 0.2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
  --icon-checkmark-color: var(--switch-bg);
  --icon-checkmark-size: 10px;
  --switch-offset: calc((var(--switch-height) - var(--circle-diameter)) / 2);
}

.switch input {
  display: none;
}

.switch {
  display: inline-block;
}

.switch svg {
  transition: var(--icon-transition);
  position: absolute;
  height: auto;
}

.checkmark {
  width: var(--icon-checkmark-size);
  color: var(--icon-checkmark-color);
}

.slider {
  box-sizing: border-box;
  width: var(--switch-width);
  height: var(--switch-height);
  background: var(--switch-dual-bg);
  border-radius: 999px;
  display: flex;
  align-items: center;
  position: relative;
  transition: var(--switch-transition);
  cursor: pointer;
}

.circle {
  width: var(--circle-diameter);
  height: var(--circle-diameter);
  background: var(--circle-bg);
  border-radius: inherit;
  box-shadow: var(--circle-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--circle-transition);
  z-index: 1;
  position: absolute;
}

.left {
  left: var(--switch-offset);
}

.right {
  left: calc(var(--switch-width) - var(--circle-diameter) - var(--switch-offset));
}