.container {
  position: relative;
  display: inline-block;
  }

.popupMenu {
  position: absolute;
  /* top: 40%; */
  /* left: 50%; */
  left: 0;
  z-index: 9999;
  padding: 10px;
  /* background-color: rgb(255, 253, 240); */
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 8px 2px;

  /* box-shadow: 3px 4px 43px -4px rgba(203, 203, 203, 0.403); */
  /* box-shadow: 3px 4px 43px -4px rgba(203, 203, 203, 0.403); */
  /* background-image: linear-gradient(to right bottom, #ecc4ee, #e7cbef, #e4d2ef, #e2d8ed, #e2deea, #e7e0eb, #ece2ec, #f0e4ec, #fee3e7, #ffe3da, #ffe8cc, #f6f0c4); */
  /* background-image: linear-gradient(to right bottom, #ecc4ee, #e7cbef, #e4d2ef, #e2d8ed, #e2deea, #e7e0eb, #ece2ec, #f0e4ec, #fee3e7, #ffe3da, #ffe8cc, #f6f0c4);  background-image: linear-gradient(to left bottom, #d7a1da, #d5aedf, #d4bbe2, #d5c7e3, #d8d2e3, #dfd5e4, #e6d9e6, #ecdde7, #fcd9df, #ffd8cd, #ffddb8, #eee6ac);  box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
  border-radius: 0 8px 8px 8px;
  width: 400px;
  overflow: visible;
}
.popupMenu.label {
  
}

button {
  cursor: pointer;
}

.title {
  margin-bottom: 10px;
  font-size: 26px;
  /* border-bottom: 1px solid #ccc; */
}

.selectLabel {
  margin-right: 5px;
}

.button {
  border: 1px solid black;
  padding: 5px 10px;
  margin-right: 3px;
  cursor: pointer;
}

.selected {
  background-color: lightblue;
}

.preview {
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center; 
}


.iconButton {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    position: relative;
}

.iconButton:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 12px 12px 0 0;
}
