.button {
  font-size: 19px;
  /* color: #fff; */
  /* background-color: #414141; */
  width: 50px;
  height: 50px;
  text-align: center;
  border-width: 0px;
  padding: 0px;
  border: 0px;
  border-radius: 0px 20px 20px 0px;
  /* margin-left: 10px; */
}

.button:hover {
  /* box-shadow: 3px 4px 11px -4px rgb(99, 99, 99); */
  background-color: #1c1c1c;
}

.button:active {
  background-color: #1c1c1c;
}