.container {
  display: flex;
  flex-direction: row;
}

.dayButton {
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 7px;
  background-color: #eee;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24);
  /* transition: background-color 0.3s ease; */
}

.dayButton:hover {
  background-color: #b3b3b38f;
}

.dayButton.selected {
  background-color: #414141;
}

.dayText {
  font-size: 18px;
  font-weight: 400;
  color: #414141;
}

.selected .dayText {
  color: #fff;
}

.monday {
  border-radius: 15px 0 0 15px;
}

.friday {
  border-radius: 0 15px 15px 0;
}