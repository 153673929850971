
/* CSS */
.button30 {
  align-items: center;
  appearance: none;
  background-color: #414141;
  border-radius: 20px;
  border-width: 0;
  /* box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset; */
  /* box-sizing: border-box; */
  color: #fcfcfc;
  cursor: pointer;
  display: inline-flex;
  /* font-family: "JetBrains Mono",monospace; */
  height: 32px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  /* transition: box-shadow .15s,transform .15s; */
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 16px;
}

.button30:focus {
  /* box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset; */
}

.button30:hover {
  box-shadow: 3px 4px 11px -4px rgb(99, 99, 99);
  /* box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset; */
  /* transform: translateY(-2px); */
}

.button30:active {
  /* box-shadow: #D6D6E7 0 3px 7px inset; */
  transform: translateY(1.5px);
}

.button30.selected {
    background-color: #939393; /* Change to your desired color */
    color: white;
  }