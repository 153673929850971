.personSelectContainer {
  background-color: #fff;
  cursor: pointer;
  border-radius: 15px;
  padding: 5px 0px 5px 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 8px 2px;
  margin-top: 10px;
}

.personSelectContainer:hover {
  background-color: #e2e2e2;
}

.selected {
  background-color: #414141;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 8px 2px;
  color: white;
}

.selected:hover {
  background-color: #1c1c1c;
}


.personRow {
  background-color: var(--isEven);
  display: flex;
}

.personRow:hover {
  background-color: #bbb;
}


/* Used in individual statistics */
.categoryName {
  cursor: pointer;
}
.categoryName:hover {
  background-color: #565656;
  color: white;
}