.daySelector {
  position: relative;
  min-width: 110px;
  border: 0;
  outline: 0;
  padding: 0;
}

.selectorButton {
  width: 100%;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 20px;
  cursor: pointer;
}
.selectorButton:hover {
  background-color: #efefef;
}

.para {
  /* font-size: 16px; */
    font-size: 0.9rem;

}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  /* width: 100%; */
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: white;
  border: 0px solid #ccc;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  /* z-index: 1000; */
}

.dropdown li {
  /* padding: 10px; */
  /* padding-left: 10px; */
  cursor: pointer;
  font-size: 0.9rem;

}

.dropdown li:hover {
  background-color: #f0f0f0;
}

.editButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 35px;
  
}
.editButton:hover {
  background-color: #d3d3d3;
}