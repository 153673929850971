.roundedDiv {
  position: relative;
  /* width: 250px; */
  /* height: 150px; */
  overflow: visible;
  display: inline-block;
  
}
/* 
.roundedBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  border-radius: 10px;
} */
.one,
.two,
.three {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff; /* doesnt do anything i think */
  box-shadow:  0 1px 1px rgba(0, 0, 0, 0.24);

  
}



.one::before,
.two::before,
.three::before {
  content: ''; /* Ensure pseudo-element is rendered */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 15px;
}

.one::before {
  box-shadow: 3px 4px 43px -4px rgba(203, 203, 203, 0.403);
  z-index: -10;
}

.two::before {
  box-shadow: 3px 4px 43px -4px rgba(201,201,201,0.44);
  z-index: -9;
}

.three::before {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 8px 2px;
  z-index: -8;
}

.content {
  position: relative;
  /* padding: 15px; */
  /* z-index: 2; */ 
}

.content h2 {
  color: white;
  margin-bottom: 10px;
}

.sub-component {
  position: relative;
  display: inline-block;
}

.popup {
  position: absolute;
  z-index: 10;
  padding: 10px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  border-radius: 5px;
  overflow: visible;
  top: 100%;
  left: 0;
  white-space: nowrap;
}