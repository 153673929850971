body {
  /* margin: 0; */
  font-family:  'Axiforma', 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #fbfbfe; */
  /* background-image: linear-gradient(to right top, #fbfbfe, #fefbfd, #fffbfc, #fffcfa, #fffdfa, #fffdfa, #fffdfa, #fffdfa, #fffcfa, #fffbfc, #fefbfd, #fbfbfe); */
  background-image: url(https://i.pinimg.com/originals/54/90/61/549061adcfc746745b33b29b3280c17d.jpg);
  /* background-image: url('./assets/19661314198_5b93aa68ae_k.jpg'); */
  

  /* background-image: linear-gradient(to right top, #d7a1da, #d5aedf, #d4bbe2, #d5c7e3, #d8d2e3, #dfd5e4, #e6d9e6, #ecdde7, #fcd9df, #ffd8cd, #ffddb8, #eee6ac); */

  /* background-image: linear-gradient(to right top, #654667, #634a67, #624d66, #615165, #605464, #665764, #6c5a65, #715d65, #7d6263, #85685e, #86715b, #817c5c); */
  /* background-image: linear-gradient(to right top, #d7a1da, #d5aedf, #d4bbe2, #d5c7e3, #d8d2e3, #dfd4e4, #e6d7e5, #ecdae5, #fcd4da, #ffd2c4, #ffd5ac, #e7de9c); */
  /* background-image: linear-gradient(to left bottom, #d7a1da, #d5aedf, #d4bbe2, #d5c7e3, #d8d2e3, #dfd4e4, #e6d7e5, #ecdae5, #fcd4da, #ffd2c4, #ffd5ac, #e7de9c); */
  background-size: cover;
  background-repeat: no-repeat;
  /* min-height: 100vh; */
  background-attachment: fixed;

}

html, body {
  /* height: 100%; */
  margin-bottom: 0px;
  padding-bottom: 0px;
}

p, button, input {
  font-family: 'Axiforma', 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

/* p, input, span {
  color: #222; // Not needed
} */

::selection {
  background-color: #594cec;
  color: white;
}
::-moz-selection {
  background-color: #594cec;
  color: white;
}

/*'CustomFont',*/
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


