/* CSS */
.input-30 {
  appearance: none;
  background-color: #c5ceff;
  border: 0px solid #D6D6E7;
  border-radius: 20px;
  /* box-shadow: #2d234233 0 1px 2px, rgba(45, 35, 66, 0.1) 0 2px 4px -1px, #D6D6E7 0 -1px 0 inset; */
  /* box-sizing: border-box; */
  color: #000000;
  /* font-family: "JetBrains Mono", monospace; */
  height: 30px;
  width: 55px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
  outline: none;
  transition: box-shadow .15s, transform .15s, border-color .15s;
  text-align: center;
  box-shadow:  0 1px 2px rgba(0, 0, 0, 0.24);
  /* font-size: 1.1em; */
}
/* Duplicate for the BH start*/
.input-31 {
  appearance: none;
  background-color: #dde1e4;
  border: 0px solid #D6D6E7;
  border-radius: 20px;

  /* box-shadow: #2d234233 0 1px 2px, rgba(45, 35, 66, 0.1) 0 2px 4px -1px, #D6D6E7 0 -1px 0 inset; */
  /* box-sizing: border-box; */
  color: #36395A;
  /* font-family: "JetBrains Mono", monospace; */
  height: 30px;
  width: 55px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
  outline: none;
  transition: box-shadow .15s, transform .15s, border-color .15s;
  text-align: center;
  box-shadow:  0 1px 2px rgba(0, 0, 0, 0.24);

}

.titleTextArea {
  appearance: none;
  background-color: #c5d9ff;
  border: 0px solid #D6D6E7;
  border-radius: 20px;
  /* box-shadow: #2d234233 0 1px 2px, rgba(45, 35, 66, 0.1) 0 2px 4px -1px, #D6D6E7 0 -1px 0 inset; */
  /* box-sizing: border-box; */
  color: #36395A;
  /* font-family: "JetBrains Mono", monospace; */
  /* height: 60px; */
  width: 55px;
  padding: 10px;
  font-size: 16px;
  outline: none;
  transition: box-shadow .15s, transform .15s, border-color .15s;
  /* text-align: center; */
  box-shadow:  0 1px 2px rgba(0, 0, 0, 0.24);
}
.descriptionTextArea {
  appearance: none;
  background-color: #c5d9ff;
  border: 0px solid #D6D6E7;
  border-radius: 20px;
  /* box-shadow: #2d234233 0 1px 2px, rgba(45, 35, 66, 0.1) 0 2px 4px -1px, #D6D6E7 0 -1px 0 inset; */
  /* box-sizing: border-box; */
  color: #36395A;
  /* font-family: "JetBrains Mono", monospace; */
  /* height: 60px; */
  width: 55px;
  padding: 10px;
  font-size: 16px;
  outline: none;
  transition: box-shadow .15s, transform .15s, border-color .15s;
  /* text-align: center; */
  box-shadow:  0 1px 2px rgba(0, 0, 0, 0.24);
}

.input-30:focus {
  /* border-color: #36395A; */
  /* box-shadow: #D6D6E7 0 0 0 1px inset, rgba(45, 35, 66, 0.2) 0 1px 2px, rgba(45, 35, 66, 0.1) 0 2px 4px -1px, #D6D6E7 0 -1px 0 inset; */
}

.input-30:hover {
  /* box-shadow: rgba(45, 35, 66, 0.2) 0 2px 4px, rgba(45, 35, 66, 0.1) 0 4px 8px -2px, #D6D6E7 0 -1px 0 inset; */
  /* transform: translateY(-1px); */
}

.input-30:active {
  /* box-shadow: #D6D6E7 0 1px 3px inset; */
  /* transform: translateY(1px); */
}
