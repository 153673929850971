.incButton {
  border: 0;
  background-color: #fff0;
  border-radius: 8px;
}

.incButton:hover {
  background-color: #fafafa;
  outline: 1px solid #ccc;
}

.incButton:active {
  transform: translateY(1.5px);
}