.container {
    /* background-color: var(--fourth-color); */
    margin-bottom: 5px;
    max-width: 100%;
    align-items: center;
}

.mamaContainer {
    margin-bottom: 100px;
}

.header {
    margin: 0;
    padding: 10px 0;
    width: 5%;
}

.flexContainer {
    display: flex;
    width: 100%;
    /* margin: auto; */
    /* align-items: flex-start; */
}

.column {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.row {
    display: flex;
    justify-content: space-between;
}

.flexItem {
    flex: 1;
    margin-right: 10px;
}


.daySummation {
    flex: 1;
    /* background-color: var(--secondary-color); */
    padding: 5;
    margin-left: 10px;
    max-width: 10%;
}

.input {
    max-width: 5%;
}

p {
    margin: 2px;
}

.addButton {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* z-index: 1000; */
  }
  
  .popup h3 {
    margin-top: 0;
  }
  
  .popup button {
    margin: 5px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .popup button:hover {
    background-color: #e0e0e0;
  }