/* .mainContainer {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: #fff;
  
  border-radius: 1em;
  padding: 1%;
  box-sizing: border-box;
  outline: 2px solid rgb(209, 209, 209);
} */

.mainContainer {
  /* height: 60vh; */
  /* width: 60vw; */
  background-color: #fff;
  border-radius: 1em;
  padding: 1%;
  box-sizing: border-box;
  outline: 2px solid rgb(209, 209, 209);
}

.topContainer {
  display: flex;
  flex-direction: column;
}


.peopleContainer {
  margin-right: 10px;
  margin-top: 10px;
  /* border: 2px solid #ccc;
  border-radius: 20px; */
}

.graphContainer {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 550px;
  height: 300px;
  margin-left: 0px;
  margin-right: 10px; */
}

/* .graphSubcontainer:hover {
  outline: 2px solid rgb(235, 235, 235);
  border-radius: 20px;
} */

.personContainer {
  /* background-color: #eee; */
  /* border-radius: 15px; */
  /* margin-bottom: 5px; */
  padding: 20px 0px 20px 0px;
  /* margin-left: 20px; */
  box-sizing: border-box;
  /* cursor: pointer; */
  /* margin-right: 10px; */
  border-bottom: 1px solid #ccc;
}

/* .personContainer:last-child {
  border-bottom: 2px solid #cbc6fb00;
} */
/* .personContainer:first-child {
  border-top: 1px solid #ccc;
} */

.personContainer:hover {
  /* background-color: #e3eafb; */
}

.personName {
  cursor: pointer;
}
.personName:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px; 
}

.settingsIcon {
  cursor: pointer;
  border-radius: 10px;
  padding: 5px;
  margin: 0;
}
.settingsIcon:hover {
  background-color: #e8e8e8;
}

.title {
  font-size: 2em;
  /* color: rgb(53, 109, 255); */
  /* color: #a4c7ff; */
  color: #222;
  font-weight: 500;
  /* margin-left: 8px; */
  color: rgb(83, 149, 255);
  cursor: pointer;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.title:hover {
  outline: 1px solid #ccc;
  box-sizing: border-box;
  background-color: #fff;
  color: #f7f7ff;
}



.graphContainer {
  /* background-color: #e5e3fb; */
  border-radius: 1em;
}

.graphSubContainer {
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}


.paragraph {
  font-size: 16px;
  color: #333;
  cursor: pointer;
  /* outline: 2px solid rgba(209, 209, 209, 0); */
  outline: 1px solid #ccc0;
  border-radius: 20px;
  /* padding: 5px; */
  white-space: nowrap;
  margin: 0;
  padding: 0;
}

.paragraph:hover {
  /* outline: 2px solid rgb(209, 209, 209); */
  /* outline: 1px solid #ccc; */
  text-decoration: underline;
  text-decoration-thickness: 1px; 
}

.tooltip {
  position: absolute;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-size: 14px;
  color: #000;
  pointer-events: none;
  cursor: default;
  transition: opacity 0.1s ease-in-out;
  opacity: 1;
  z-index: 1000;
  white-space: nowrap;
  outline: 2px solid rgb(209, 209, 209);
}



.graphTypeSelectText {
  cursor: pointer;
}
.graphTypeSelectText:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px; 
}

/* .selectedGraphType {
  text-decoration: underline;
  text-decoration-thickness: 1px; 
} */

.meetingAHButton {
  /* border: 1px solid #fff0; */
  border: 0;
}

.selectedMeetingAHButton {
  /* border: 1px solid black; */
  text-decoration: underline;

}

