.container {
    display: flex;
    flex-direction: column;
  }
  
.weekItem {
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 60px;
    border: 0px;
    box-shadow:  0 1px 2px rgba(0, 0, 0, 0.24);
  }
  
  .weekItem:not(:first-child) {
    /* margin-top: -1px; */
  }
  
  .weekItem span {
    color: #414141;
    font-size: 20px;
    font-weight: 400;
  }
  
  .firstWeek {
    border-radius: 15px 15px 0 0;
  }
  
  .lastWeek {
    border-radius: 0 0 15px 15px;
  }
  
  .defaultWeek {
    background-color: rgba(255, 255, 255, 0.329);
    z-index: 1;
  }
  
  .hoveredWeek {
    background-color: #b3b3b38f;
    z-index: 2;
  }
  
  .selectedWeek {
    background-color: #414141;
    z-index: 2;
  }
  .selectedWeek span {
    color: #fff;
  }
  
  .addRemoveContainer {
    /* margin-top: 1px; bruh what is this */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  /* Note: The .button-31 class is assumed to be defined elsewhere */