.daySelector {
  position: relative;
  /* width: 80px; */
  box-sizing: border-box;
  background-color: #fff0;
  border: 0px solid #ccc0;
  /* border-radius: 20px; */
  border-radius: 20px 20px 0 0;
  padding-left: 5px;
  width: 200px;
}

.selectorButton {
  /* width: 100%; */
  /* padding: 5px; */
  background-color: #fff0;
  border: 0px solid #ccc0;
  outline: 0px solid #fff0;
  box-sizing: border-box;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
  padding-bottom: 0;
}
.daySelector:hover {
  background-color: #fff;
  outline: 1px solid #ccc;
  box-sizing: border-box;

}

.para {
  /* font-size: 16px; */
  white-space: nowrap;
  font-size: 2rem;
  /* color: rgb(53, 109, 255); */
  /* color: #a4c7ff; */
  color: #222;
  font-weight: 500;
  /* margin-left: 8px; */
  color: rgb(83, 149, 255);
  /* cursor: pointer; */
  /* border-radius: 20px; */
  padding: 0;
  margin: 0;
  
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: white;
  /* border: 0px solid #ccc; */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  border-radius: 0 0 20px 20px;
  outline: 1px solid #ccc;
  box-sizing: border-box;

}

/* .dropdown li:first-child  {
  border-radius: 20px 20px 0 0;
} */

.dropdown li:last-child {
  border-radius: 0 0 20px 20px;
}

.dropdown li {
  padding: 10px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #f0f0f0;
}