.container {
  position: relative;
  width: 200px;
  height: 50px;
  /* background-color: #F3F3F6; */
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  /* z-index: 2; */
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #F3F3F6;
  overflow: hidden;
  transition: height 0.3s ease;
  transition-delay: 0.3s; /* Add delay here */
}

.container:hover .dropdown {
  height: 100%;
}

/* Reset the delay when un-hovering */
.container:not(:hover) .dropdown {
  transition-delay: 0s;
}

.dropdown button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #F3F3F6;
  cursor: pointer;
}

.dropdown button:hover {
  background-color: #F3F3F6;
}