.progress-circle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  margin: 0px;
  margin-left: 0px;
}

.progress-ring {
  transform: rotate(-90deg);
}

.progress-ring__circle {
  transition: 0.35s stroke-dashoffset;
  transform-origin: 50% 50%;
  stroke-linecap: round;
}

.percentage-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  font-weight: 600;
  color: #000;
}
